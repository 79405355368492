import { render, staticRenderFns } from "./Page.vue?vue&type=template&id=b25330b0&scoped=true&"
import script from "./Page.vue?vue&type=script&lang=js&"
export * from "./Page.vue?vue&type=script&lang=js&"
import style0 from "./Page.vue?vue&type=style&index=0&id=b25330b0&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b25330b0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FixedBackground: require('/vercel/path0/components/elements/FixedBackground.vue').default,PageHeader: require('/vercel/path0/components/page/Header.vue').default,ContentEasterEgg: require('/vercel/path0/components/content/EasterEgg.vue').default,Button: require('/vercel/path0/components/elements/Button.vue').default,PageFooter: require('/vercel/path0/components/page/Footer.vue').default})
