
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters({
      easterEgg: 'ui/isEasterEggActive'
    })
  },
  watch: {
    easterEgg() {
      //   setTimeout(this.toggleEasterEgg(), 1000);
    }
  },
  methods: {
    toggleEasterEgg() {
      this.$store.commit('ui/toggleEasterEgg', false);
    },
    getRandomRotation(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    }
  }
};
