// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/main-bg-mobile.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/main-bg.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fixed-background{background:#00ffbc url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 100% 0;background-size:cover;height:100vh;left:0;position:fixed;top:0;width:100%;z-index:-1}@media (min-width:768px){.fixed-background{background:#00ffbc url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat 100% 0;background-size:cover}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
