
import { mapGetters } from 'vuex';

export default {
  name: 'PageComponent',
  props: {
    data: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      show: false
    };
  },
  computed: {
    ...mapGetters({
      sitemap: 'settings/sitemap',
      infoPages: 'settings/infoPageNavigation'
    })
  },
  mounted() {
    // Useful resource to understand this line:
    // https://dmitripavlutin.com/vue-next-tick/
    this.$nextTick(() => (this.show = true));
  }
};
